import React from "react";
import moment from 'moment';
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import DefaultModal from '../../components/DefaultModal';
import DefaultSpinner from "../../components/DefaultSpinner";
import { DefaultAjax } from "../../components/DefaultAjax";
import {  Row, Col, Table } from 'reactstrap';
import SelectGIE from '../../components/SelectGIE';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DefaultPagination from '../../components/DefaultPagination';
import {stopPropagation} from "../../components/SidebarFunctions";
import { downloadInvoicePdf, billingContentShow, creditNoteContentShow, renderBillingRow, getDataForSearchOrShowAll } from "./InvoiceFunctions";
import { dateRangeForSearh, handleDateRangeCallback, catchErrorAjax, calculateScroll } from "../../components/mainFunctions"; 
import PropTypes from 'prop-types';
import DefaultModalReport from "../../components/DefaultModalReport";

export const url = process.env.REACT_APP_API_URL;

class ElectronicBillingIndex extends React.Component {
    constructor(props){
        super(props);
        this.state={
            modalContent:"",
            modalContent2:"",
            instanceType: props.instanceType,
            independent: props.independent,
            instanceTypeDescription:"",
            castMzate:"",
            spinner:"",
            token: props.token, 
            startDate: moment().add(),
            endDate: moment().add(),
            selectOrgType:"",
            dateRange: [],
            arrayElectronicBilling: [],
            currentBillingShow: "",
            creditNote: "",
            currentBillingPhase:0,
            customerBillingPhase: {},
            offset: 0,
            perPage: 24,
            currentPage: 1,
            paginationHtml:"",
            contentPagination: "",
            language: props.language
        } 
        this.state.dateRange.push(dateRangeForSearh(new Date(moment())));
        this.state.dateRange.push(dateRangeForSearh(new Date(moment())));
    } 

    componentDidMount() {
        let token;
        if(!this.state.token){
            this.setState({token: document.querySelector("#token").value});
        }else{
            token = this.state.token;
            document.querySelector("#token").value = token;
        }

        calculateScroll(170);

        this.setState((prevState) => ({
            spinner:<DefaultSpinner instanceType={prevState.instanceType} 
            independent={prevState.independent}/>,
            selectOrgType: <SelectGIE classSelect={"col-sm-12 col-md-2 col-lg-2 col-xl-2 selectGIE"} classSelectType={"col-sm-12 col-md-2 col-lg-2 col-xl-2 displayNone"}/>
        }))

        this.getAllBilling();
    } 

    handlePageClick = (e) => {
        const selectedPage = e.selected+1;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
        this.getAllBilling(true);
    };


    getAllBilling = (isSearch,isShowAll) => {

        this.setState((prevState) => ({
            contentPagination: "",
            spinner: <DefaultSpinner
            instanceType={prevState.instanceType}
            independent={prevState.independent}
            />
        }))

        setTimeout(() => {
            
            const params = getDataForSearchOrShowAll(isSearch,isShowAll,this.state.dateRange[0],this.state.dateRange[1],this.state.perPage,this.state.currentPage,this.setState.bind(this));

            DefaultAjax('').get(`/fePyInvoice?${params.toString()}`)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    this.setState({
                        arrayElectronicBilling: result.invoiceList,
                        spinner:"",
                    })

                    const contentPagination = result.invoiceList.map(billing =>
                        renderBillingRow(billing, this.showElectronicBilling, this.downloadBillingPdf)
                    );

                    this.setState({
                        pageCount: Math.ceil(result.totalCount / this.state.perPage),
                        
                        contentPagination
                    })
                    setTimeout(()=> {
                        this.setState({
                            paginationHtml:<DefaultPagination className={'mzatePaginationClass'}  data={result.invoiceList} 
                            handlePageClick={this.handlePageClick} perPage={this.state.perPage} totalCount={result.totalCount}
                            />})
                    }, 100);

                }
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
        }, 100);
    } 

    handleCallback = (event, picker) => {
        handleDateRangeCallback(picker.startDate, picker.endDate, this.setState.bind(this));
    }

    showElectronicBilling = (billing) => {
        this.setState({currentBillingShow:billing});
        this.setState({modalContent:""});
        let modalContent;
        modalContent = billingContentShow(billing);

        let modalTitle = i18n.translate("default_bill") + " " + billing.invoiceNumber;
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("download_pdf"), function: this.downloadBillingPdf},
            {text: i18n.translate("default_close"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={modalTitle}
            content={modalContent} buttons={buttons} size='lg'/>})
        }, 10);
    }

 
    showCreditNote = () => {
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,
            modalContent2:""
        });

        DefaultAjax('').get("electronicBilling/electronicBilling?billingId="+this.state.currentBillingShow.id)
        .then((res) => {
            if(res){
                this.setState({
                    spinner:""
                });
                let result = JSON.parse(res.data)
                let modalContent;
                modalContent = creditNoteContentShow(result);
                setTimeout(()=> {
                    let buttons = [{text: i18n.translate("default_close"), function: ""}];  
                    this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("credit_note")}
                    content={modalContent} buttons={buttons} size='lg'/>})
                }, 10);
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 

    downloadBillingPdf = (e,billing) => {
        stopPropagation(e);

        if(!billing){
            billing = this.state.currentBillingShow
        } 

        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        });

        downloadInvoicePdf(billing)
        .then(success => {
            if (success) {
                this.setState({spinner:""})
            }
        })
        .catch(error => {
            catchErrorAjax(error, this.setState.bind(this));
        });

    } 

    modalGenerateReports = () => {
        this.setState({modalContent:""})
        setTimeout(()=> { 
            this.setState({
                modalContent:<DefaultModalReport ajaxUrl={'/fePy/generateReport'} 
                setState={this.setState.bind(this)} 
                userSelected={document.querySelector("#userSelectedDescription").value} 
                userSelectedValue= {document.querySelector("#userSelected").value}
                selectType={document.querySelector(".selectType").value}
                moduleCode='VISITS' defaultStartDate={this.state.startDate} 
                defaultEndDate={this.state.endDate}  />
            });
        }, 10);
    } 
    
    render(){
        return (
            <>
                {this.state.modalContent}
                {this.state.modalContent2}
                <nav className='navbar' style={{display: 'contents'}}>
                    <Row>
                        {this.state.selectOrgType} 
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("date")}</label>
                            <div>
                                <DateRangePicker
                                    initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate,
                                        locale : {
                                            applyLabel: this.state.language === 'es' ? 'Aplicar' : 'Apply',
                                            cancelLabel: this.state.language === 'es' ? 'Cancelar' : 'Cancel',
                                        } 
                                    }}
                                    onEvent={this.handleCallback}
                                >
                                    <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard DateRangePicker" />
                                </DateRangePicker>
                                <input type="hidden" id="dateInInputStart"/>
                                <input type="hidden" id="dateInInputEnd"/>
                            </div>
                        </Col> 
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("default_client")}</label>
                            <input type="text" id="customerToSearch" className="form-control inputStandard"/>
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("document_number")}</label>
                            <input type="text" id="documentNumberToSearch" className="form-control inputStandard"/>
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("invoice_number")}</label>
                            <input type="text" id="invoiceNumberToSearch" className="form-control inputStandard"/>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "22px" }}>
                        <div style={{ display: 'inline-block' }} className="divIndex">
                            <button className='buttonMzateSecondary' onClick={()=> this.getAllBilling(true)}>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_search")}</span>
                            </button>
                        </div>
                        {' '}
                        <div style={{ display: 'inline-block' }} className="divIndex">
                            <button className='buttonMzateSecondary' onClick={()=> this.getAllBilling(false,true)}>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_show_all")}</span>
                            </button>
                        </div> 
                        {' '}
                        <div style={{ display: 'inline-block' }} className="divIndex">
                            <button className='buttonMzateSecondary' onClick={()=> this.modalGenerateReports()}>
                                
                                <span>{i18n.translate("generate_report")}</span>
                            </button>
                        </div> 
                    </div>
                </nav>
                <br/>
                <div>
                    {this.state.spinner}
                    <div id="divTitle">
                        <span className="title">{i18n.translate("default_bills")}</span>
                        {this.state.paginationHtml}
                    </div>
                    <div className='bodyContent' id='bodyContent'>
                        <Table className='table table-hover'>
                            <tbody>
                                <tr>
                                    <th className='firstcell'>{i18n.translate("date_issue")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_number")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_customer_name")}</th>
                                    <th className='cellgroup'>{i18n.translate("document_number")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_exempt")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_vat_5")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_vat_10")}</th>
                                    <th className='cellgroup'>{i18n.translate("net_total")}</th>
                                    <th className='cellgroup'>{i18n.translate("default_user")}</th>
                                    <th className='cellgroup'>{i18n.translate("download_pdf")}</th>
                                </tr>
                                {this.state.contentPagination}
                                {this.state.arrayElectronicBilling.length == 0 && <tr>
                                        <td colSpan={10} style={{textAlign:'center'}}>
                                            {i18n.translate("there_are_no_results_for_the_search")} 
                                        </td>
                                    </tr>} 
                            </tbody>
                        </Table>
                    </div>
                </div>
            </>
        )
    } 
} 

ElectronicBillingIndex.propTypes = {
    token: PropTypes.string.isRequired, 
    independent: PropTypes.string.isRequired,
    instanceType: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
};

export default ElectronicBillingIndex;