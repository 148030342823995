import React from "react";
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart, ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, 
        Title, Tooltip, Legend, Filler} from 'chart.js';
import { Row, Col, Table } from 'reactstrap';
import { catchErrorAjax, calculateScroll, toastFunction } from "../../components/mainFunctions";
import i18n from "i18n-react";

// Registra los elementos y escalas necesarios
Chart.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, Filler);

export const url = process.env.REACT_APP_API_URL;

class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalContent: "",
            modalContent2: "",
            spinner: "",
            pieData: {
                labels: ['Rojo', 'Azul', 'Amarillo'],
                datasets: [
                    {
                        data: [300, 50, 100],
                        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                    },
                ],
            },
            barData: {
                labels: ['Usuario1', 'Usuario2', 'Usuario3', 'Usuario4', 'Usuario5', 'Usuario6'],
                datasets: [
                    {
                        label: 'Documentos utilizados',
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: 'rgba(75,192,192,1)',
                        borderWidth: 1,
                        data: [65, 59, 80, 81, 56, 55],
                    },
                ],
            },
            areaData: {
                labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
                datasets: [
                    {
                        label: 'Documentos Disponibles',
                        fill: true,
                        backgroundColor: 'rgba(75,192,192,0.2)',
                        borderColor: 'rgba(75,192,192,1)',
                        data: [100, 90, 80, 70, 60, 50],
                    },
                    {
                        label: 'Documentos Utilizados',
                        fill: true,
                        backgroundColor: 'rgba(255,99,132,0.2)',
                        borderColor: 'rgba(255,99,132,1)',
                        data: [30, 50, 40, 20, 10, 5],
                    },
                ],
            }
        };
    }
    
    componentDidMount() {
        console.log("Statistics");
        calculateScroll(15);
    }

    render() {
        const areaDataOptions = {
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: true, // Asegúrate de que los tooltips estén habilitados
                    mode: 'index', // Muestra tooltips para todos los datasets en el mismo índice
                    intersect: false, // Permite que los tooltips aparezcan incluso cuando el mouse no está directamente sobre el punto
                    callbacks: {
                        label: (context) => {
                            const datasetLabel = context.dataset.label || '';
                            const dataPoint = context.raw;
                            return `${datasetLabel}: ${dataPoint}`;
                        },
                    },
                },
                title: {
                    display: true,
                    text: 'Últimos 6 meses',
                }
            },
        };
        

        return (
            <div className='bodyContent' id='bodyContent'>
                <div>
                    <Row>
                        <Col>
                            <div style={{ width: '100%', height: '100%' }}>
                                <Line 
                                    data={this.state.areaData} 
                                    options={areaDataOptions} 
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <br />
                <div>
                    <Row>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("default_search")}</label>
                            <input type="text" className="form-control inputStandard" />
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>Periodo</label>
                            <select className="form-select selectStandard">
                                <option value="1">Actual</option>
                                <option value="2">-----</option>
                            </select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <h2>Documentos utilizados/Documentos disponibles</h2>
                            <div style={{ width: '100%', height: '90%' }}>
                                <Pie data={this.state.pieData} options={{ responsive: true }} />
                            </div>
                        </Col>
                        <Col>
                            <Table className='table table-hover'>
                                <tbody>
                                    <tr>
                                        <th className='firstcell'>Usuario</th>
                                        <th className='firstcell'>Documentos Predeterminado</th>
                                        <th className='firstcell'>Documentos Plan</th>
                                        <th className='firstcell'>Documentos OnDemand</th>
                                        <th className='firstcell'>Total Documentos</th>
                                        <th className='firstcell'>Documentos Utilizados</th>
                                        <th className='firstcell'>Documentos Disponibles</th>
                                    </tr>
                                    <tr>
                                        <td>username - fullname</td>
                                        <td>50</td>
                                        <td>+250</td>
                                        <td>20</td>
                                        <td>320</td>
                                        <td>280</td>
                                        <td>40</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <div>
                        <h2>Gráfico de Barras</h2>
                        <div style={{ width: '100%', height: '90%' }}>
                            <Bar data={this.state.barData} options={{ responsive: true }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Statistics;
