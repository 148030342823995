import React from "react";
import { SidebarDataElectronicBilling } from "./SidebarDataElectronicBilling";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import FooterSidebar from "./FooterSidebar";

const SidebarElectronicBilling = () => {
	React.useEffect(() => {
		function handleResize() {
			let nav = document.querySelector("#nav-top").offsetHeight;
			let contentDiv = document.querySelector("#contentElectronicBilling");
			let altura = window.innerHeight - nav;
			contentDiv.style.height = altura+"px";
		}
		window.addEventListener('resize', handleResize)
	})
	return (
		<IconContext.Provider value={{color:"#fff"}}>
			
			<ul className="ulSidebar" id="ulSidebar">
				{SidebarDataElectronicBilling.map((item, index) => {
					return <SubMenu item={item} key={index}/>;
				})}
			</ul>
			<FooterSidebar url="electronicBilling"/>
			
		</IconContext.Provider>
	);
};

export default SidebarElectronicBilling;